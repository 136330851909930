import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { Link } from "gatsby"

function CardBannerAlt() {
  return (
    <Card variant="outlined" style={{ display: "flex", marginBottom:"30px" }}>
      <CardMedia
        style={{ width: "200px" }}
        // image="https://m.media-amazon.com/images/I/41r0lDjZ64L.jpg"
        image="https://i.imgur.com/xuKcHDbl.jpg" alt="7 vitamins preview"
        // Title of the image, shows on hover
        title="Natural Afro Recipes Book"
      />

      {/* <div>
      <a
        href="https://www.amazon.co.uk/DIY-Recipes-Curly-Afro-Hair-ebook/dp/B08NW1XY9B/ref=as_li_ss_il?_encoding=UTF8&psc=1&refRID=FXJZVVWM91XZ36SFJAS3&linkCode=li1&tag=bodyweight00-21&linkId=b0a374fafbddabb1c69eb34dbbe04881&language=en_GB"
        target="_blank nofollow norefferer"
      >
        <img
          border="0"
          alt=""
          src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08NW1XY9B&Format=_SL110_&ID=AsinImage&MarketPlace=GB&ServiceVersion=20070822&WS=1&tag=bodyweight00-21&language=en_GB"
        />
      </a>
      <img
        src="https://ir-uk.amazon-adsystem.com/e/ir?t=bodyweight00-21&language=en_GB&l=li1&o=2&a=B08NW1XY9B"
        width="1"
        height="1"
        border="0"
        alt=""
        style={{ border: "none !important", margin: "0px" }}
      />
</div> */}

      <div
        style={{
          textAlign: "center",
          width: "90%",
          paddingLeft: "auto",
          paddingRight: "auto"
        }}
      >
        <CardContent
          style={{
            textAlign: "center"
          }}
        >
          <Typography
            component="h5"
            variant="h5"
            style={{ fontSize: "1.2rem" }}
          >
            7 vitamins and minerals for growing healthy afro hair
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ lineHeight: 1.2, paddingTop: "5px", paddingBottom:"10px", color: "orchid" }}
          >
            Everything you need to know
          </Typography>
          {/* <p style={{ textAlign: "left" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            ultricies interdum urna, a maximus nisl malesuada et. Aliquam
            bibendum arcu vehicula, porttitor augue et, finibus arcu.
          </p> */}
          <p style={{ textAlign: "left" }}>
            We need vitamins to grow strong healthy hair but which ones are they? Here's the complete list and exactly where you can find them.
          </p>

          <Link to="/7-vitamins-for-healthy-hair/" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            //href="https://www.amazon.co.uk/kindle-dbs/hz/signup?tag=natural036-21"
            target="_blank"
            rel="noopener noreferrer nofollow"
            style={{
              backgroundColor: "orchid",
              marginTop: "0px",
              fontWeight: "600"
            }}
            //disableElevation
          >
            READ NOW
          </Button>
          </Link>

        </CardContent>
      </div>
    </Card>
  );
}

export default CardBannerAlt;
