import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"
// import CardBannerA from "../components/cardBannerAltA"
import CardBannerAlt from "../components/cardBannerAlt"

// import CardBannerRec from "../components/cardBannerRec"
// import CardBannerSq from "../components/cardBannerSq"

const FourteenTips = () => (
  <Layout>
    <SEO
      title="14 tips on how to grow afro hair"
      description="Finally, everything you need to know about how to grow an afro. We interviewed experts to help find these 14 tips to help you grow your hair fast"
    />
    <h1>14 tips on how to grow afro hair</h1>
    <h2 style={{color:"Orchid"}}>Everything you need to know about growing strong healthy afro hair</h2>

    <p>Growing afro hair can be one of the most annoying things in the world. No matter what you try, sometimes it feels like it just won't grow, it's always dry or any other problem it can give us. That's probably why you're here right?</p>
    <p>The good news is that growing natural doesn't have to be hard, frustrating or any of those things. In fact, it can be so easy that you will wonder what the fuss was ever about...</p>
    <p>We have come up with these 14 afro hair care tips to help you grow beautiful afro hair quickly. They range from using a <a
        href="https://naturalafrohaircare.com/silk-pillowcases-for-hair-care/"
        target="_blank"
        rel="noopener noreferrer"
        // style={{ textDecoration: "none" }}
      >
      silk pillowcase
      </a> to protect your hair at night, all the way to the <a
        href="https://naturalafrohaircare.com/7-vitamins-for-healthy-hair/"
        target="_blank"
        rel="noopener noreferrer"
        // style={{ textDecoration: "none" }}
      >
      vitamins and minerals required for natural hair growth
      </a>. When you've finished reading and you know exactly what to do next, check out our list of our favourite<a
        href="https://naturalafrohaircare.com/afro-hair-products/"
        target="_blank"
        rel="noopener noreferrer"
        // style={{ textDecoration: "none" }}
      >
      {" "}products for natural afro hair
      </a>
    . We would love to hear about your progress so get in touch and let us know what worked for you.</p>

    <div style={{ textAlign: "center" }}>
      <img
        //src="https://i.imgur.com/BHl1dfp.jpg"
        src="https://i.imgur.com/kQKseD3.jpg"
        alt="Natural afro hair care tips to grow afro hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/photos/23KdVfc395A"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Thought Catalog</a></p>
    </div>

    <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>1. Keep it moisturised</h3>
    <p>Dry hair is the cause of most hair problems. It can be frustrating when it seems like our hair absorbs anything we put in within 5 minutes. We can be chasing defined curls and just end up with frizz that we dread combing later on. Your main focus should be finding ways to keep moisture in your hair which include oils, conditioners and good practices. We go over some of the ways in other tips.</p>
    <p>If you are struggling to find products that work for you and want to support black owned businesses; we have a growing list of {" "}
      <Link to="/black-owned-hair-products/" 
      // style={{ textDecoration: "none"}}
      >
      black owned hair products</Link>. Check it regularly for updates and share the list to show support.</p>
    <p>Learn more with our guide - <Link to="/5-reasons-to-keep-your-hair-moisturised/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    5 reasons to keep your hair moisturised
            </Link>.</p>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>2. Try oil</h3>
    <p>Coconut oil and Castor oil can be great if they work for you. Everyone is different but some people live by one of these or even both. They are great for locking in moisture after washing and conditioning. If you don’t already, give one a try and see if it works for you. There are plenty of oils for natural hair growth including <a
        href="https://www.amazon.com/USDA-Organic-Jojoba-Oil-Unrefined/dp/B01A74442I/ref=as_li_ss_tl?dchild=1&keywords=jojoba+oil&qid=1590914002&sr=8-5&linkCode=ll1&tag=natural032d-20&linkId=d64bb769f3163a071475b78934e0c864&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >
      Jojoba
      </a> and <a
        href="https://www.amazon.com/dp/B07QBLQ7V6/ref=as_li_ss_tl?dchild=1&keywords=argan+oil&qid=1590914108&sr=8-4&linkCode=ll1&tag=natural032d-20&linkId=2500e3b0f9e435260fedf53703e44a60&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >
      Argan
      </a>, shop around and see what works for you. You can even use your favourite leave in conditioner afterwards depending on how moisturised your hair is feeling. Read our interviews with <Link to="/talking-with-blessed-shaba/" 
      // style={{ textDecoration: "none" }}
      >
      Blessed
    </Link> and <Link to="/natural-hair-with-manna/" 
    // style={{ textDecoration: "none" }}
    >
    Manna
    </Link> for more on this.</p>

    <p>Author Abi Faniran has written a book called {" "}<a
        href="https://www.amazon.com/Grow-Your-Hair-Healthy-Natural-ebook-dp-B012IUDYFC/dp/B012IUDYFC?_encoding=UTF8&me=&qid=1615413694&linkCode=ll1&tag=natural032d-20&linkId=4f108947013f0d55994140f55ce26f8c&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >How to Grow Your Hair Healthy & Long With Natural Oils</a>. It’s written for people who want to start using oils to correct problems they might have. I’ve written a bit more about it in the bonus section ahead.</p>
      <p>Learn more with our guide - <Link to="/5-reasons-to-try-oil-natural-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    5 reasons to try oil on natural hair
            </Link>.</p>

      {/* GREEN FLAT */}
      <div style={{ textAlign: "center", marginBottom:"20px" }}>
      <a href="https://nahc.gumroad.com/l/100tipspreview"
         target="_blank"
         rel="noopener noreferrer">
      <img
        src="https://i.imgur.com/dFtdOLv.png"
        alt="100 tips for natural hair"
        style={{marginBottom: "5px"}}
      />
      </a>
    </div>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>3. Protective styling</h3>
    <p>We all know natural hair is beautiful and we can be tempted to wear it out all the time. Depending on your climate and how well you look after your hair, this could be a bad thing. We recommend finding protective styles that look good so can be worn out and do the job. Protective styles make sure your hair isn’t easily damaged by extreme temperatures which can cause loss of moisture and breakage. They also can make it easier to wrap up and wear a hat if it gets cold or rains. A benefit of some protective styles is that they can lock in the moisture so when you take them out you feel beautiful silky hair. 
        {" "}<Link to="/talking-with-leah-alexxanderr-caine/" 
        //style={{ textDecoration: "none" }}
        >
    Leah 
    </Link> spoke about her hairstyles in her interview.</p>
    <p>We love natural hair and we know we know there are different types of people in our community. Some will only wear their own hair, others are open to braids & weaves and will never chemically treat their own hair. The natural hair community is more open now so find what works for you and have confidence. If you choose to braid or weave your hair, your stylist might give you the option to buy your own <a
        href="https://www.amazon.com/X-pression-Ultra-Braid-pack-1B/dp/B071NDY9FJ/ref=as_li_ss_tl?dchild=1&keywords=braiding+hair&qid=1590914172&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=9f10627dd80ed75f75b49b7cd49543e7&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      braiding hair
      </a> or <a
        href="https://www.amazon.com/Molefi-Brazilian-Bundles-Unprocessed-Extensions/dp/B0786LKLM9/ref=as_li_ss_tl?dchild=1&keywords=weaving+hair&qid=1590914293&sr=8-6&linkCode=ll1&tag=natural032d-20&linkId=3083f0dc9f8a128e1b13e86e486156b4&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      weaving hair
      </a> which can save you some money. The cost can vary a lot depending on if you go for human hair or synthetic and where you buy from.</p>
      <p>Check out our article on <Link to="/tips-on-box-braids-for-black-hair/" 
      // style={{ textDecoration: "none" }}
      >box braids</Link> for more on protective styling. We've also written about <Link to="/wigs-for-black-women/" 
      // style={{ textDecoration: "none" }}
      >
      wigs</Link> and the surprising benefits of them if you're trying to grow you hair.</p>
      <p>Learn more with our guide - <Link to="/7-benefits-of-protective-styling/" 
      style={{ 
        textDecoration: "none", 
        color:"Orchid", fontWeight:"600" }}>
    7 benefits of protective styling
            </Link>.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>4. Deep condition</h3>
      <p>Deep conditioning is key to replace any lost nutrients from our hair. Many people use home made products for this and base them around a main protein ingredient. Hair is absorbent and after a wash with no product applied yet is the perfect time for a deep condition. This is because there is nothing sealing/ blocking the absorption of nutrients. <Link to="/talking-with-tyler/" 
      // style={{ textDecoration: "none" }}
      >
      Tyler
    </Link> loves doing this.</p>
    <p>Learn more with our guide - <Link to="/5-reasons-why-you-should-deep-condition/" style={{ 
      textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    5 reasons why you should deep condition
            </Link>.</p>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>5. Go homemade</h3>
    <p>As we touched on above, some people use home made products and we love this. Shop bought products contain natural products but also can have manmade chemicals that keep the ingredients from separating and to make them look pretty so we buy them. With homemade products we can rest assured that there is nothing but whatever we choose to include. It can be daunting but it is a good opportunity to experiment and find out what your hair likes. A quick google search should bring up some recipes.</p>
    <p>Some popular ingredients we have come across include coconut oil, jojoba oil, raw shea butter, aloe vera gel and raw egg (yes egg) just to name a few. Speak to your grandma or aunty, you never know, there might already be a recipe in the family. <Link to="/talking-natural-hair-with-celai-west/" 
    //style={{ textDecoration: "none" }}
    >
    Celai
    </Link> detailed her homemade hair tea, which she made with her mother, when we interviewed her. This one is great for if you're trying to figure out how to treat afro hair at home.</p>
    <p>Learn more about the pros and cons of <Link to="/going-homemade-with-natural-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    going home-made
            </Link>.</p>
    {/* <p>I was browsing one of the natural hair groups on Facebook and came across Verona White who had written a book called 20 DIY Recipes for Curly Afro Hair. It's an amazing book and each recipe uses natural ingredients. She put a lot of time and effort into sharing her secrets, hopefully it will help some of you guys. We made a little feature below, click through to check it out.</p> */}
   
   {/* 3D pink */}
   <div style={{ textAlign: "center", marginBottom:"20px" }}>
      <a href="https://nahc.gumroad.com/l/100tipspreview"
         target="_blank"
         rel="noopener noreferrer">
      <img
        src="https://i.imgur.com/kpICDZQ.png"
        alt="100 tips for natural hair"
        style={{marginBottom: "5px"}}
      />
      </a>
    </div>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>6. Don't wash your hair too frequently</h3>
    <p>You should be washing your hair roughly once a week at the most. Moisture is key for our hair to grow and a high volume wash routine can leave our hair dry and without protection which will ultimately lead to damage and breakage. Many people wash once a week and others wash once every two weeks. <Link to="/talking-with-tyler/" 
    // style={{ textDecoration: "none" }}
    >
    Tyler 
    </Link> and <Link to="/talking-with-sharna/" 
    // style={{ textDecoration: "none" }}
    >
    Sharna
    </Link> talk about this. Find what works for you.</p>
    <p>Learn more about <Link to="/how-often-you-should-wash-natural-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    how often you should wash natural hair
            </Link>.</p>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>7. Cut off split/ dead ends</h3>
    <p>While it can be tempting to hold onto all of your hair and wait for it to grow. Not getting rid of split and damaged ends can be stunting your hair growth. As new hair grows from the roots, the rate of breakage from the ends can match it. This makes it look like your hair has stopped growing. Many people are surprised that when they clean up their hair routine, their hair grows past what they thought for possible for their head. Don’t be afraid, just cut off split and damaged ends. Many people are fans of this technique, <Link to="/talking-with-sharna/" 
    // style={{ textDecoration: "none" }}
    >
    Sharna
    </Link>, <Link to="/talking-with-tyler/" 
    // style={{ textDecoration: "none" }}
    >
    Tyler
    </Link> & <Link to="/natural-hair-with-manna/" 
    // style={{ textDecoration: "none" }}
    >
    Manna
    </Link> feel passionately about this one.</p>
    <p>Learn more about why you should <Link to="/cut-off-split-ends/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    cut off split ends
            </Link> for faster natural hair growth.</p>

    {/* 14 TIPS POSTER */}
    {/* <div style={{ textAlign: "center", marginBottom:"20px" }}>
      <a href="https://nahc.gumroad.com/l/Ixhfj"
         target="_blank"
         rel="noopener noreferrer">
      <img
        src="https://i.imgur.com/7Wsm5dW.png"
        alt="Natural afro hair care tips to grow afro hair"
        style={{marginBottom: "5px"}}
      />
      </a>
    </div> */}

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>8. Apply product to freshly washed hair</h3>
    <p>As soon as you have washed your hair and dried it enough to stop the drips, apply product. The job of a good product is to seal in the moisture. If you are applying product to dry hair, it can appear moisturised because of the shine but you can actually be sealing dry hair which can cause damage in the long run. Try <a
        href="https://www.amazon.com/Natural-Jamaican-Coconut-Textures-Healthy/dp/B00N2JN1L6/ref=as_li_ss_tl?dchild=1&keywords=castor+oil&qid=1590914484&sr=8-15&linkCode=ll1&tag=natural032d-20&linkId=27d7ceb7d8a66d3df87413d47c9b414a&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      castor oil
      </a> or a <a
        href="https://www.amazon.com/Shea-Moisture-Strengthen-Restore-Conditioner/dp/B00MXD6GBY/ref=as_li_ss_tl?crid=14HB0BK1JYPRQ&dchild=1&keywords=leave+in+conditioner&qid=1590914557&sprefix=leave+in+,aps,224&sr=8-4&linkCode=ll1&tag=natural032d-20&linkId=6bfd2bb56a224975baea7c0883d784aa&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      leave in conditioner
      </a>.</p>

      {/* <CardBannerA/> */}
      {/* <CardBannerSq/> */}

      {/* green open */}
   <div style={{ textAlign: "center", marginBottom:"20px" }}>
      <a href="https://nahc.gumroad.com/l/100tipspreview"
         target="_blank"
         rel="noopener noreferrer">
      <img
        src="https://i.imgur.com/5MRt9pz.png"
        alt="100 tips for natural hair"
        style={{marginBottom: "5px"}}
      />
      </a>
    </div>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>9. Apply product before applying heat</h3>
      <p>Following on from above, it can be tempting to blow dry your hair straight after a wash just to get rid of the excess water but this is a quick way to damage your hair. The ends of your hair are more susceptible to damage simply because it is older and this is the first part that will dry. While you wait for your scalp areas to dry you will continually damage the middle and ends. To be safe, towel dry delicately to stop drips then apply product and blow dry if you have to. Don’t use the hottest temperature. <Link to="/talking-with-blessed-shaba/" 
      // style={{ textDecoration: "none" }}
      >
      Blessed
    </Link> uses <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >
      pink hair lotion
      </a>.</p>
      <p>Learn more about why you should <Link to="/apply-product-before-heat/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    apply product before heat
            </Link> for healthy natural hair.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>10. Wrap up your hair</h3>
      <p>Wrapping up your hair overnight is a must. The pillow covers will drain the moisture from your hair. Get a silk head wrap or <a
        href="https://www.amazon.com/Ms-Remi-Deluxe-Extra-Jumbo-Night/dp/B007F21X70/ref=as_li_ss_tl?dchild=1&keywords=hair+bonnet&qid=1590914640&sr=8-5&linkCode=ll1&tag=natural032d-20&linkId=542948a5c54e386569d328bca567dd43&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >
      bonnet
      </a> of some sort. Some people can be irritated by sleeping with something on their head so a <a
        href="https://www.amazon.com/Bedsure-Satin-Pillowcase-Hair-2-Pack/dp/B0725WFLMB/ref=as_li_ss_tl?dchild=1&keywords=silk+pillowcase&qid=1590914725&sr=8-7&linkCode=ll1&tag=natural032d-20&linkId=1527e0f404648b67774c7dbea7085417&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >
      silk pillow case
      </a> is an alternative. <Link to="/talking-natural-hair-with-ebonie-roberge/" 
      //style={{ textDecoration: "none" }}
      >
      Ebonie
    </Link> lives by this one, it really useful for afro hair growth.</p> 
    <p>We've got a whole <Link to="/silk-pillowcases-for-hair-care/" 
    //style={{ textDecoration: "none" }}
    >
    guide on pillowcases for hair care
    </Link> and it takes you through the differences between silk and satin.</p>

    <p>Learn more about why you should <Link to="/wrap-up-your-natural-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    wrap up your hair
            </Link> day and night.</p>

    <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>11. Use different products for your hair and scalp</h3>
    <p>Hair products are great for protecting your hair and encouraging growth but can be bad for your scalp. Your hair and scalp should be treated differently. Your scalp wants to be able to breathe, it can get irritated if the pores get blocked which can cause your head to itch and even cause spots. Some of us are more sensitive to others. A healthy scalp can encourage hair, it shouldn’t be dry. <Link to="/talking-with-blessed-shaba/" 
    // style={{ textDecoration: "none" }}
    >
    Blessed
    </Link> talks about this in her interview, she uses <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      pink hair lotion
      </a> for her hair and <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      pure coconut oil
      </a> for her scalp.</p>

      <p>Learn more about why you should <Link to="/use-different-products-for-hair-and-scalp/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    use different products for your hair and scalp
            </Link>.</p>

             {/* green open */}
   <div style={{ textAlign: "center", marginBottom:"20px" }}>
      <a href="https://nahc.gumroad.com/l/100tipspreview"
         target="_blank"
         rel="noopener noreferrer">
      <img
        src="https://i.imgur.com/5dTfGGM.png"
        alt="100 tips for natural hair"
        style={{marginBottom: "5px"}}
      />
      </a>
    </div>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>12. Look after your edges</h3>
      <p>For some of us, edges can be really frustrating while for others their edge hair seems as strong as the rest of the head. Edges can be easy to damage because they are right on the hair line and are the first line of defence. When you style your hair make sure you don’t neglect your edges. You can use styling gel or moisturise slightly more frequently than the rest of your hair. <Link to="talking-with-leah-alexxanderr-caine/" 
      //style={{ textDecoration: "none" }}
      >
      Leah
    </Link> uses <a
        href="https://www.amazon.com/Styler-Professional-Styling-Olive-Hold/dp/B004X8KOEC/ref=as_li_ss_tl?dchild=1&keywords=eco+styler+gel&qid=1590914884&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=1437c2d9a35b96f077aecf1992906371&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Eco-styler gel
      </a>.</p>

      <p>Here's some more tips on <Link to="/look-after-your-edges/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    how to look after your edges
            </Link>.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>13. Careful combing</h3>
      <p>Make sure your hair has lots of moisture when you come it. It is far easier to detangle moisturised hair and minimise self inflicted breakage. When your hair is dry, you could be used to combing as fast as possible whilst grabbing hold of the roots to minimise pain. The truth is, hair doesn’t have to be painful to come. Sometimes being wet is all that is needed but if you have extra tight curls <a
        href="https://www.amazon.com/Aunt-Jackies-Instant-Detangling-Enriched/dp/B008R40C22/ref=as_li_ss_tl?dchild=1&keywords=detangler&qid=1590914926&sr=8-14&linkCode=ll1&tag=natural032d-20&linkId=48fe0bf87c4d8129e55fe0916490fba6&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      try a detangler
      </a>. Try a big tooth comb too.</p>

      <p>Learn more about why you should <Link to="/careful-combing-tips-natural-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    detangle carefully for healthier hair
            </Link> and how.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>14. Take action and do what feels right for you</h3>
      <p>The advice in this article is no secret. You would probably have heard of many of these tips at some point. The key is to take action, don’t be afraid to try out some of what you have  read here. It can be hard to break habits but that is exactly what you will need to do to grow your hair. To make it easy, take the tip that stood out most to you and try it first. Also, there is lots of advice all over the internet. It is easy to get lost however, everyone is different and what works for someone else might not work for you. Ultimately, you have to experiment, don’t be afraid to fail and do what works for you.</p>
      <p>Here are some tips about <Link to="/take-action-on-what-feels-right/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    taking action
            </Link> to get results.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>15. (BONUS) - Look at your diet</h3>
      <p>It still surprises us how many people neglect their diet. Many benefits come from a healthy balanced diet, not just beautiful hair. This is a good place to start, the problem is that it can often be the most difficult thing to implement because our diets are a big part of us. Try to be honest with yourself and cut out anything that might be harming your health. Do research and find products that can help you. One thing we are big fans of is reducing your intake of dairy or cutting it completely if you can.</p>

      <CardBannerAlt/>
      {/* <CardBannerRec/> */}

      <h4 style={{fontSize:"1.6rem", fontWeight:"400"}}>Vitamins & Supplements</h4>
      <p>Vitamins for afro hair growth is a topic that has been debated for a long time. Some people live by them and see results quickly, whereas others don't like the idea and say your normal diet is just fine. There are plenty of hair growth vitamins and Biotin is the one that stands out for black hair.</p>
      <p>It is part of the Vitamin B family and is required for strong healthy nails and hair. People with a Vitamin B deficiency often have thin hair and brittle nails although this is very rare. The vitamin is naturally occurring in foods such as eggs, almonds and some seeds.</p>
      <p>You can get biotin supplements in three forms, soft-gels, tablets and liquid. The <a
        href="https://www.amazon.com/Absorption-10000mcg-Artificial-Preservatives-Friendly/dp/B07XTR574Q/ref=as_li_ss_tl?dchild=1&keywords=biotin+liquid&qid=1591722079&sr=8-2&th=1&linkCode=ll1&tag=natural032d-20&linkId=8491d5600c62bed14b96832ce5e2c1d6&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      liquid biotin
      </a> is popular because it allows you to control the dosage more easily, which brings me to the negatives.</p>
      <p>Lots of people have complained that it gives them bad skin/ acne although want to keep using it because it helps their hair grow faster, so they follow these rules;</p>

      <ol>
          <li>Start with a low dosage and if you start getting acne, lower the dosage even more.</li>
          <li>Drink a LOT lots of water. Many people said this helped them. Set a goal to drink so many litres a days and make sure you reach it.</li>
          <li>Take a multivitamin containing other members of the vitamin B family. These are said to help your body break Biotin down more easily.</li>
      </ol>

      <div style={{textAlign:"center"}}>
      <a href="https://www.amazon.com/Potency-Supports-Verified-Certified-Veggie-Softgels/dp/B00T56FQAE/ref=as_li_ss_il?dchild=1&amp;keywords=Abliquid+biotin&amp;qid=1595584333&amp;sr=8-4&amp;linkCode=li3&amp;tag=natural032d-20&amp;linkId=a28927784156399fe359016957b29085&amp;language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img class="aligncenter" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&amp;ASIN=B00T56FQAE&amp;Format=_SL250_&amp;ID=AsinImage&amp;MarketPlace=US&amp;ServiceVersion=20070822&amp;WS=1&amp;tag=natural032d-20&amp;language=en_US" alt="biotin cocnut oil" border="0" /></a>
      </div>

      <p>There is little scientific evidence that Biotin actually works and as I have said there are conflicting views on the supplement. Make sure to do your own research, <a
        href="https://www.amazon.com/Absorption-10000mcg-Artificial-Preservatives-Friendly/dp/B07XTR574Q/ref=as_li_ss_tl?dchild=1&keywords=biotin+liquid&qid=1591722079&sr=8-2&th=1&linkCode=ll1&tag=natural032d-20&linkId=8491d5600c62bed14b96832ce5e2c1d6&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        // style={{ textDecoration: "none" }}
      >
      read reviews
      </a> and please be careful.</p>

      <p>We have written a <Link to="/7-vitamins-for-healthy-hair/" 
      //style={{ textDecoration: "none" }}
      >
      complete guide on the 7 vitamins and minerals you need to grow strong healthy hair
    </Link> so check it out if you want to learn more.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>16. (BONUS) - Education, read books</h3>
      <p>Learning how to take care of your hair is something that can take a long time but once you know how, it will never leave you. It is something you can pass down to your children and share with family members. Chances are, they have very similar hair to yourself. My point is, enjoy the journey and don't worry about how long it takes to learn how to get there.</p>
      <p>Reading books is a great way of learning about our hair. Authors spend a lot of time researching and editing them to be perfect so we don't have to go and scrape together all the information ourselves. It's amazing that we get years worth of effort in just a few clicks.</p>
      <p>On Amazon you can search for "afro hair ebook", then use the filter to sort by best rated. I found some books on hair care techniques, the history of afro hair and even children's books to give them confidence in their hair early on. <a
        href="https://www.amazon.com/s/ref=as_li_ss_tl?k=afro+hair+ebook&rh=p_72:2661618011&dc&qid=1596448915&rnid=2661617011&ref=sr_nr_p_72_1&linkCode=ll2&tag=natural032d-20&linkId=ead3e591cf883e577ae85d65649f9d12&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Here's the results
      </a>.</p>

      <p>Here's how and why you should <Link to="/natural-hair-education/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    get educated on natural hair
            </Link> to get the results you want.</p>


      <p>If you have a <a
        href="https://www.amazon.com/Kindle-Paperwhite-Waterproof-Storage-Special/dp/B07PS737QQ/ref=as_li_ss_tl?dchild=1&keywords=kindle&qid=1596450356&sr=8-5-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExMlQ0UzdES1JZVzdOJmVuY3J5cHRlZElkPUEwMjE2MTMyMTBES1hXSjRRSjE5VyZlbmNyeXB0ZWRBZElkPUEwNDgzODUwMVRJQzVCSkxRWkRaViZ3aWRnZXROYW1lPXNwX210ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=&linkCode=ll1&tag=natural032d-20&linkId=beadeadd70e351518925cad3343d144f&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Kindle ebook reader
      </a>, you can get started straight away. If not, you can order a lot of these as a physical copy. Here's a few that are worth a look;</p>

      <div style={{textAlign:"center"}}>
      <a href="https://www.amazon.com/You-Love-Will-Grow-Beautiful-ebook/dp/B00756AJ08/ref=as_li_ss_il?dchild=1&amp;keywords=afro+hair+ebook&amp;qid=1596448915&amp;refinements=p_72:2661618011&amp;rnid=2661617011&amp;sr=8-10&amp;linkCode=li3&amp;tag=natural032d-20&amp;linkId=c7205ce8dbe4685befd269ef6cdf0131&amp;language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img alt="If You Love It, It Will Grow: A Guide To Healthy, Beautiful Natural Hair" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&amp;ASIN=B00756AJ08&amp;Format=_SL250_&amp;ID=AsinImage&amp;MarketPlace=US&amp;ServiceVersion=20070822&amp;WS=1&amp;tag=natural032d-20&amp;language=en_US" border="0" /></a>
      {/* <img style={{border: "none !important", margin: "0px !important"}} src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&amp;language=en_US&amp;l=li3&amp;o=1&amp;a=B00756AJ08" alt="If You Love It, It Will Grow: A Guide To Healthy, Beautiful Natural Hair" width="1" height="1" border="0" /> */}
      </div>

     <p><strong>If You Love It, It Will Grow: A Guide To Healthy, Beautiful Natural Hair</strong> - Written by the best selling author, Dr. Phoenyx Austin, this book is a filled with expert advice on how to grow afro textured hair. If you want a complete guide on all the things that are good and bad for our hair, this is for you. <a
        href="https://www.amazon.com/You-Love-Will-Grow-Beautiful-ebook/dp/B00756AJ08/ref=as_li_ss_tl?dchild=1&keywords=afro+hair+ebook&qid=1596448915&refinements=p_72:2661618011&rnid=2661617011&sr=8-10&linkCode=ll1&tag=natural032d-20&linkId=51eb629996a63b1fc4d36704e56f11c4&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Get it here
      </a>.</p>

      <p><strong>The Science of Black Hair: A Comprehensive Guide to Textured Hair Care</strong> - As the title says, this is a deep look at afro textured hair. So if you are a person who likes to understand "why" you are doing something, this is for you. You don't have to be a scientist to understand the book, it is written in a way that anyone can pick it up and learn from it. <a
        href="https://www.amazon.com/Science-Black-Hair-Comprehensive-Textured-ebook/dp/B0063IYJVE/ref=as_li_ss_tl?dchild=1&keywords=afro+hair+ebook&qid=1596448915&refinements=p_72:2661618011&rnid=2661617011&sr=8-6&linkCode=ll1&tag=natural032d-20&linkId=cd1edb4a59731acb0d4d228c1b7bd923&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Learn more here
      </a>.</p>

      <p><strong>Hair Love</strong> - An amazing children's book that is focused on a black father and daughter. It's very popular so you might have seen this one already. If not, you are definitely missing out. <a
        href="https://www.amazon.com/Hair-Love-Matthew-Cherry/dp/0525553363/ref=as_li_ss_tl?dchild=1&keywords=hair+love&qid=1596448960&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=b2c9b8933f092327269f54fa74723357&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Check it out here
      </a> and read the reviews.</p>

      <p><strong>How to Grow Your Hair Healthy & Long with Natural Oils: Choose the Right Oils & Learn How to Use Them to Achieve Optimal Growth</strong> - Abi Faniran starts off the book by answering a lot of questions about why natural oils are good, how they are produced and which ones are best for our hair. She also covers the difference between oils and moisturisers so one definitely doesn’t replace the other.</p>
      <p>In the following sections we learn about all the different hair issues and how oils help. These include hair thinning, hair loss, scalp issues, breakage, frizzy hair and so on.</p>
      <p>It is a complete guide with over 150 ages of information where Abi covers 40 different natural oils and real up to date recipes to give you the best chance of getting the hair you want. It’s easy to read and is full of useful information that you can use straight away. <a
        href="https://www.amazon.com/Grow-Your-Hair-Healthy-Natural-ebook-dp-B012IUDYFC/dp/B012IUDYFC?_encoding=UTF8&me=&qid=1615413694&linkCode=ll1&tag=natural032d-20&linkId=4f108947013f0d55994140f55ce26f8c&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      Get it here
      </a>. You can look inside for a preview on this one.</p>

      <h3 style={{fontSize:"1.8rem", fontWeight:"400"}}>Shopping Online</h3>
      <p>Hey guys, we know our readers are from all over the world. Most of our product suggestions lead to Amazon because they have nearly everything. The problem is that all of you don’t have access to Amazon so if you see anything you like and want to buy online, you can try AliExpress who ship worldwide.</p>
      <p>AliExpress - China’s answer to Amazon. Better for individuals, just look out for unusual pricing on products. Good for raw products like shea butter and essential oils.</p>
      <p>Amazon - Here's the <a
        href="https://www.amazon.com/s/ref=as_li_ss_tl?k=afro+hair+products&rh=p_72:2661618011&dc&qid=1595583982&rnid=2661617011&ref=sr_nr_p_72_1&linkCode=ll2&tag=natural032d-20&linkId=6f15fb0656681ad8b7e950241f796c71&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        //style={{ textDecoration: "none" }}
      >
      highest rated afro hair products
      </a> on Amazon.</p>

      <h2>Conclusion</h2>
      <p>There are plenty or natural hair products and remedies out there. For some people, you will get lucky and it will work for you quickly. For most of us, it will take a while. The problem with hair is that it can seem to grow slowly. To see results, we have to choose something and stick to it for a while.</p>
      <p>When it comes to afro hair growth, we struggle a lot more than people with other hair types. It's like some of them can get long health hair seemingly by accident. Normal products, washing everyday, a few trims a year and done. The great thing is that when we get it right, we can <a
        href="https://stayglam.com/beauty/natural-hairstyles/"
        target="_blank"
        rel="noopener noreferrer"
        //style={{ textDecoration: "none" }}
      >
      wear our hair
      </a> like a crown and it is something to be proud of.</p>
      <p>If you've been wondering how to grow afro hair fast, that's probably the wrong way to look at it. Your hair will grow at the rate it usually grows assuming you are healthy. What you should focus on is things like keeping your natural hair regrowth in tact. This means focusing on keeping the hair from breaking and falling off due to neglect, lack of a proper hair care routine and damage. The tips above should give you what you need to get started on your journey to healthy natural hair.</p>
      <p>Like these tips? Let us know. Share your journey with us, if not with us then share them with your community.</p>

      <p>Follow us on IG <a
        href="https://www.instagram.com/naturalafrohair/"
        target="_blank"
        rel="noopener noreferrer"
        //style={{ textDecoration: "none" }}
      >
      @naturalafrohair
      </a> and Facebook <a
        href="https://www.facebook.com/naturalafrohaircare/"
        target="_blank"
        rel="noopener noreferrer"
        //style={{ textDecoration: "none" }}
      >
      @naturalafrohaircare
      </a></p>

      <p>What are your thoughts on this article? Do you have anything to add to the list? Are you planning to try any of these tips out or has some of them already worked for you? We are always looking for black hair growth tips to share with our community and you can help. We have great article that lists of our favourite <Link to="/afro-hair-products/" 
      //style={{ textDecoration: "none" }}
      >
      afro hair products
    </Link>, check it out. Remember to try the <a
        href="http://naturalafrohaircare.com/product-app/"
        target="_blank"
        rel="noopener noreferrer"
        //style={{ textDecoration: "none" }}
      >
      product selector
      </a>, it's our new tool that lets you choose your hair type and it gives you personalised product recommendations.</p>

      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>As an Amazon Associate I earn from qualifying purchases. Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

      









    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
          <p>
            <Link to="/cantu-review/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Cantu Beauty Review
            </Link>{" "}
            - Everything you need to know about Cantu Beauty and their hair products. Find out if they are right for you and what you should look out for...
          </p>
          <p>
            <Link to="/afro-hair-trends-2021/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Afro Hair Trends 2021
            </Link>{" "}
            - A look into the popularity of black hair styles, trends and techniques for the past year. Find out how your searches compare to other people around the world....
          </p>     
          <p>
            <Link to="/natural-hair-with-manna/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Model, Artist and Musician - Natural Hair with Manna McLeod
            </Link>{" "}
            - We talk natural hair with the beautiful model Manna. We picked up some tips and learn about some new products. Can’t wait for you to read!...
          </p>              
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default FourteenTips
